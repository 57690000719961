import Cookies from "js-cookie";
import Vue from "vue";

const TokenKey = "token";

export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
  //return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return localStorage.clear();
  //return Cookies.remove(TokenKey)
}
