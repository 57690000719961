import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VCharts from 'v-charts'
import Element, {Message} from 'element-ui';
import  settings from './settings.js';
import '@/styles/index.scss'
// import './theme/index.css';
import './styles/element-variables.scss'
import 'normalize.css/normalize.css'

import http from './common/axios.js'
import store from './store/index.js'
import ElTreeSelect from 'el-tree-select';
import We7CopyRight from './components/We7CopyRight.vue';

import Cookies from 'js-cookie'

import moment from 'moment';
Vue.prototype.$moment = moment;


import i18n from './lang' // internationalization
import './icons/index' // icon
import './permission.js' // permission control
import './utils/error-log' // error log
import * as filters from './filters/index' // global filters

Vue.use(ElTreeSelect);
Vue.component('We7CopyRight',We7CopyRight);

Vue.prototype.http = http;	//全局调用axios

Vue.config.productionTip = false;

Vue.use(VCharts);

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

//关闭vue调试
const isDebug_mode = process.env.NODE_ENV !== 'production';
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

Vue.prototype.$store = store;
let myVueEventHub = new Vue();
Vue.prototype.$eventHub = myVueEventHub;

let serverHost = window.location.protocol+'//'+location.hostname;

if(window.location.href.indexOf('addons') === -1){
  Vue.prototype.$isWeiQin = false;
  Vue.prototype.$serverRequestRoot = serverHost;

}else{
  Vue.prototype.$isWeiQin = true;
  Vue.prototype.$serverRequestRoot = serverHost + '/addons/xunlian_suyuan/core/index.php';
}

if (window.location.href.indexOf("addons") === -1) {
  Vue.prototype.$isWeiQin = false;
} else {
  Vue.prototype.$isWeiQin = true;
}

window.showError = error => {
  try {
    console.error(error);
    let message;
    if(typeof(error) === "string"){
      message = error;
    }else if (error.response && ( error.response.msg || error.response.message)){
      message = error.response.msg || error.response.message;
    }else if (error.response && error.response.data&&(error.response.data.msg||error.response.data.message)){
      message = error.response.data.msg||error.response.data.message;
    } else {
      message = error.msg || error.message || "未知错误信息";
    }
    Message.error(message);
  } catch (e) {}
}
Vue.prototype.$showError = window.showError;

Vue.config.errorHandler = (error, vm, mes) => {
  let info = {
    type: "script",
    code: 0,
    message: error.message,
    url: window.location.href,
    stack: error.stack,
  };
}
// 新统一样式弹出层
import dialog from './system-components/dialog/dialog.vue'
Vue.component('design-dialog', dialog);


// 所有的UI组件的公共控件
import All_form_unit from './system-components/form-unit/index.js';
Vue.use(All_form_unit);

import All_ui_unit from './ui-component/component-unit/index.js'; // 所有的UI组件的公共控件
Vue.use(All_ui_unit);

// 组件级别懒加载
import VueLazyComponent from '@xunlei/vue-lazy-component';
Vue.use(VueLazyComponent);

// pixel 转 rem
Vue.prototype.$px2rem = function (pixel = 0) {
  return pixel + 'px';
};
if(Vue.prototype.$isWeiQin){
  if (process.env.NODE_ENV === 'production') {
    Vue.prototype.$ue = "/front/static/UEditor/";  //百度编辑器路径
    Vue.prototype.$public = "";
    Vue.prototype.$ue_uploads = settings.apiBaseURL + "admin/ue_uploads";//百度编辑器上传路径
  } else {
    Vue.prototype.$ue = "/front/static/UEditor/";  //百度编辑器路径
    Vue.prototype.$public = "";
    Vue.prototype.$ue_uploads = settings.apiBaseURL + "admin/ue_uploads";//百度编辑器上传路径
  }
}else {
  if (process.env.NODE_ENV === 'production') {
    Vue.prototype.$ue = "/front/static/UEditor/";  //百度编辑器路径
    Vue.prototype.$public = "";
    Vue.prototype.$ue_uploads = settings.apiBaseURL + "admin/ue_uploads";//百度编辑器上传路径
  } else {
    Vue.prototype.$ue = "/front/static/UEditor/";  //百度编辑器路径
    Vue.prototype.$public = "";
    Vue.prototype.$ue_uploads = settings.apiBaseURL + "admin/ue_uploads";//百度编辑器上传路径
  }
}

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


window.vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

//正在加载
let loader = document.getElementById("loader-wrapper")
if(loader){
  var loadingtip = loader.style.display="none";
}

