<template>
  <div class="header-notice">
      <div @click="handleNoticeClick">
        <el-badge :value="needList.length?needList.length:0" class="item">
          <i class="el-icon-close-notification" style="font-size:18px"/>
        </el-badge>
      </div>
      <el-drawer
        title="通知"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose">
        <el-card class="box-card" v-if="needList.length">
          <div slot="header" class="clearfix">
            <span>系统更新通知</span>
          </div>
          <div v-for="(item,index) in needList" :key="index" @click="handleUpdateClick">
            系统升级了，最新版本{{ item.version }}
          </div>
        </el-card>

      </el-drawer>
<!--      <el-dropdown-menu slot="dropdown">-->
<!--        <el-dropdown-item command="update" v-for="(item,index) in needList" :key="index"><i class="el-icon-warning"-->
<!--                                                                                            style="font-size:10px"/>-->
<!--          系统升级了，最新版本{{ item.version }}-->
<!--        </el-dropdown-item>-->
<!--      </el-dropdown-menu>-->
  </div>
</template>

<script>
import {getSystemVersion} from "@/api/system.js"
export default {
  name: "Notice",
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      needList: []
    }
  },
  mounted() {
    this.getNotict();
  },
  methods: {
    handleNoticeClick(){
      this.drawer = true;
    },
    handleClose(done) {
      done();
    },
    handleUpdateClick() {
      this.$router.push({path: '/set/system_update'})
      this.drawer = false;
    },
    getNotict() {
      getSystemVersion()
        .then((res) => {
          if (res.status === 200) {
            this.needList = res.data.updateInfo;
          } else {
            showError(res);
          }
        }).catch((error) => {
        showError(error);
      });
    },
  }
};
</script>
<style lang="less" scoped>


.item {
  margin-top: 0;
  margin-right: 0;
  /deep/.el-badge__content{
    margin-top:10px;
  }
}
.header-notice .ivu-dropdown-item {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 22px;
  color: #515a6e;
}
.header-notice .ivu-dropdown-item ~ .ivu-dropdown-item {
  border-top: 1px solid #e8eaec;
}
.header-notice .iconImg {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
}
.header-notice .ivu-dropdown {
  height: 30px;
  line-height: 30px;
}
.header-notice .ivu-dropdown .ivu-select-dropdown {
  margin-top: 22px;
}
.header-notice .ivu-badge-dot {
  z-index: 0 !important;
}
</style>
