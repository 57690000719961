import Cookies from "js-cookie";
import { getLanguage } from "@/lang";
import { Message } from "element-ui";

const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : true,
    withoutAnimation: false,
  },
  device: "desktop",
  language: getLanguage(),
  size: Cookies.get("size") || "medium",
  errorList: [],
  hasReadErrorPage: false,
};

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1);
    } else {
      Cookies.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set("language", language);
  },
  SET_SIZE: (state, size) => {
    state.size = size;
    Cookies.set("size", size);
  },
  addError(state, error) {
    state.errorList.push(error);
  },
  setHasReadErrorLoggerStatus(state, status = true) {
    state.hasReadErrorPage = status;
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  },
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },
  setSize({ commit }, size) {
    commit("SET_SIZE", size);
  },
  addErrorLog({ commit, rootState }, info) {
    if (!window.location.href.includes("error_logger_page"))
      commit("setHasReadErrorLoggerStatus", false);
    const {
      user: { token, name },
    } = rootState;
    let data = {
      ...info,
      time: Date.parse(new Date()),
      token,
      name,
    };
    let stack = info.stack.split("\n");
    // Message.error(stack[0]+stack[1])
    console.error(info.stack);
    // saveErrorLogger(info).then(() => {
    //   commit('addError', data)
    // })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
