import request from "@/libs/request.js";

export function login(data) {
  return request({
    url: "/admin/login",
    method: "post",
    data,
  });
}

export function getInfo() {
  return request({
    url: "/admin/get_userinfo",
    method: "post",
  });
}

export function logout() {
  return request({
    url: "/admin/logout",
    method: "post",
  });
}
