<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">
      <template >
        <el-tooltip content="搜索" effect="dark" placement="bottom">
        <search id="header-search" class="right-menu-item" />
        </el-tooltip>
          <el-tooltip content="错误日志" effect="dark" placement="bottom">
        <error-log class="errLog-container right-menu-item hover-effect" />
          </el-tooltip>
        <el-tooltip content="全屏" effect="dark" placement="bottom">
          <screenfull id="screenfull" class="right-menu-item hover-effect" />
        </el-tooltip>
        <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
<!--        <lang-select class="right-menu-item hover-effect" />-->
        <el-tooltip content="设置" effect="dark" placement="bottom">
          <system-setting class="right-menu-item hover-effect">
            <settings></settings>
          </system-setting>
        </el-tooltip>
      </template>
      <el-tooltip content="通知" effect="dark" placement="bottom">
        <HeaderNotice class="right-menu-item hover-effect"></HeaderNotice>
      </el-tooltip>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        @command="head"
      >
        <div class="avatar-wrapper">
          <div style="display: inline">{{ name }}</div>
          <i class="el-icon-caret-bottom" />
        </div>

        <el-dropdown-menu slot="dropdown" @command="edit">
          <el-dropdown-item divided command="edit">
            <span style="display: block">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="lout">
            <span style="display: block">{{ $t("navbar.logOut") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="" :visible.sync="dialogFormVisible" width="35%" center>
      <el-form :model="form">
        <el-form-item label="原密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.old_psw"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.new_psw"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.password2"
            auto-complete="off"
            @keyup.enter.native="edit"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";
import ErrorLog from "@/components/ErrorLog/index.vue";
import Screenfull from "@/components/Screenfull/index.vue";
import SizeSelect from "@/components/SizeSelect/index.vue";
import LangSelect from "@/components/LangSelect/index.vue";
import Search from "@/components/HeaderSearch/index.vue";
import SystemSetting from "@/components/SystemSetting/index.vue";
import Settings from "./Settings/index.vue";
import HeaderNotice from "./HeaderNotice/index.vue";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    ErrorLog,
    Screenfull,
    SizeSelect,
    LangSelect,
    Search,
    SystemSetting,
    Settings,
    HeaderNotice,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "name", "roles"]),
  },
  data() {
    return {
      sct: "",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      form: {
        old_psw: "",
        new_psw: "",
        password2: "",
      },
      total: "",
      event: "",
      event_num: "",
    };
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    handleClose() {
      this.dialogVisible = false;
    },
    edit() {
      if (this.form.new_psw != this.form.password2) {
        this.$message({
          message: "两次输入的密码不一致",
          type: "warning",
        });
        this.form = {
          old_psw: "",
          new_psw: "",
          password2: "",
        };
        return;
      }
      this.http.post("admin/change_password", this.form).then((res) => {
        this.$message({
          message: "修改密码成功",
          type: "success",
        });
        this.form = {
          old_psw: "",
          new_psw: "",
          password2: "",
        };
        this.dialogFormVisible = false;
      });
    },
    head(command) {
      var that = this;
      if (command == "lout") {
        this.$confirm("是否退出系统？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.clear(); //清除所有缓存
            this.http.post("admin/logout", {}).then((res) => {
              if (res.status == 200) {
                this.$message({
                  type: "success",
                  message: "退出成功!",
                });
                this.$router.push({
                  path: "/login",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
      if (command == "edit") {
        this.dialogFormVisible = true;
      }
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        display:flex;
        justify-content:center;
        align-items: center;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
