
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
<!--        <img-->
<!--          v-if="siteInfo && siteInfo['login_logo']"-->
<!--          :src="siteInfo['login_logo']"-->
<!--          class="sidebar-logo-mini"-->
<!--        />-->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img
          v-if="siteInfo && siteInfo['login_logo']"
          :src="siteInfo['login_logo']"
          class="sidebar-logo"
        />
<!--        <h1 class="sidebar-title">{{ siteInfo && siteInfo.site_name }}</h1>-->
      </router-link>
    </transition>
  </div>
