<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
.el-input__inner{
 padding-right: 0 !important;
}
.center-dialog {
  top: 50%;
  transform: translateY(-50%)
}
.app-main{
  &>.admin-body{
    padding:0;

    .admin-header{
      padding:20px;
      background-color:#ffffff;
      margin-bottom:15px;
      display: flex;
      flex-wrap: wrap;
    }
    .admin-content{
      padding:20px;
      background-color:#ffffff;
      .content-header{
        margin-bottom:15px;
        display:flex;
        justify-content:space-between
      }
    }
  }
}
  .admin-body{
    padding:0;
    .admin-header{
      padding:20px;
      background-color:#ffffff;
      margin-bottom:15px;
      display: flex;
      flex-wrap: wrap;
    }
    .admin-content{
      padding:20px;
      background-color:#ffffff;
      .content-header{
        margin-bottom:15px;
        display:flex;
        justify-content:space-between
      }
    }
  }

:root {
  --color-primary: #850a0a;
  --color-primary-1: #e6f7ff;
  --color-primary-2: #bae7ff;
  --color-primary-3: #91d5ff;
  --color-primary-4: #69c0ff;
  --color-primary-5: #40a9ff;
  --color-primary-6: #1890ff;
  --color-primary-7: #096dd9;
  --color-primary-8: #0050b3;
  --color-primary-9: #003a8c;
  --color-primary-10: #002766;
  --color-success: #52c41a;
  --color-success-1: #f6ffed;
  --color-success-2: #d9f7be;
  --color-success-3: #b7eb8f;
  --color-success-4: #95de64;
  --color-success-5: #73d13d;
  --color-success-6: #52c41a;
  --color-success-7: #389e0d;
  --color-success-8: #237804;
  --color-success-9: #135200;
  --color-success-10: #092b00;
  --color-warning: #faad14;
  --color-warning-1: #fffbe6;
  --color-warning-2: #fff1b8;
  --color-warning-3: #ffe58f;
  --color-warning-4: #ffd666;
  --color-warning-5: #ffc53d;
  --color-warning-6: #faad14;
  --color-warning-7: #d48806;
  --color-warning-8: #ad6800;
  --color-warning-9: #874d00;
  --color-warning-10: #613400;
  --color-danger: #ff4d4f;
  --color-danger-1: #fff1f0;
  --color-danger-2: #ffccc7;
  --color-danger-3: #ffa39e;
  --color-danger-4: #ff7875;
  --color-danger-5: #ff4d4f;
  --color-danger-6: #f5222d;
  --color-danger-7: #cf1322;
  --color-danger-8: #a8071a;
  --color-danger-9: #820014;
  --color-danger-10: #5c0011;
  --color-info: #909399;
  --color-info-1: #f4f4f5;
  --color-info-2: #dedfe0;
  --color-info-3: #d3d4d6;
  --color-info-4: #bcbec2;
  --color-info-5: #a6a9ad;
  --color-info-6: #909399;
  --color-info-7: #6e7074;
  --color-info-8: #58595c;
  --color-info-9: #414244;
  --color-info-10: #2a2b2b;
  --color-white: #fff;
  --color-white-5: hsla(0,0%,100%,0.5);
  --color-black: #000;
  --color-black-1: rgba(0,0,0,0.1);
  --color-black-3: rgba(0,0,0,0.3);
  --color-black-5: rgba(0,0,0,0.5);
  --color-text-primary: #303133;
  --color-text-regular: #606266;
  --color-text-secondary: #909399;
  --color-text-placeholder: #c0c4cc;
  --color-text-dark-primary: #fff;
  --color-text-dark-regular: hsla(0,0%,100%,0.8);
  --border-color-base: #ddd;
  --border-color-light: #e5e5e5;
  --border-color-lighter: #ededed;
  --border-color-extra-light: #f4f4f4;
  --background-color-base: #fafafa;
  --box-shadow-base: 0 2px 4px rgba(0,0,0,0.12),0 0 6px rgba(0,0,0,0.04);
  --box-shadow-dark: 0 2px 4px rgba(0,0,0,0.12),0 0 6px rgba(0,0,0,0.12);
  --box-shadow-light: 0 4px 12px 0 rgba(0,0,0,0.15);
  --checkbox-background-color: var(--color-white);
  --radio-input-background-color: var(--color-white);
  --radio-checked-icon-color: var(--color-white);
  --input-background-color: var(--color-white);
  --button-default-background-color: var(--color-white);
  --button-disabled-background-color: var(--color-white);
  --button-default-border-color-5: hsla(0,0%,86.7%,0.5);
  --table-header-background-color: var(--background-color-base);
  --table-fixed-box-shadow: 0 0 10px rgba(0,0,0,0.12);
  --popup-modal-background-color: #000;
  --popup-modal-opacity: 0.5;
  --popover-background-color: var(--color-white);
  --dialog-box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  --tooltip-fill: var(--color-text-primary);
  --tooltip-color: var(--color-text-dark-primary);
  --tooltip-border-color: var(--tooltip-fill);
  --scrollbar-background-color: hsla(0,0%,66.7%,0.5);
  --scrollbar-hover-background-color: hsla(0,0%,66.7%,0.8);
  --backtop-background-color: var(--color-white);
  --backtop-font-color: var(--color-primary);
  --backtop-hover-background-color: var(--border-color-extra-light);
  --avatar-background-color: #c0c4cc;
  --skeleton-color: #f2f2f2;
  --skeleton-to-color: #e6e6e6;
  --svg-monochrome-grey: #dcdde0;
  --loading-mask-bg: hsla(0,0%,100%,0.9);
  --layout-body-background: #f0f2f5;
  --sidebar-light-shadow: 1px 3px 3px rgba(0,21,41,0.08);
  --sidebar-dark-shadow: 0 4px 4px rgba(0,0,0,0.35);
  --header-light-shadow: 0 1px 4px rgba(0,21,41,0.08);
  --header-dark-shadow: 0 1px 4px rgba(0,0,0,0.1);
  --header-tool-hover-bg: rgba(0,0,0,0.025);
  --header-dark-tool-hover-bg: hsla(0,0%,100%,0.05);
  --logo-light-shadow: 1px 2px 3px rgba(0,21,41,0.08);
  --logo-dark-shadow: 0 3px 4px rgba(0,0,0,0.35);
  --menu-dark-background: #191a23;
  --content-image-filter: none
}
@font-face {
  font-family: iconfont;
  src: url(//at.alicdn.com/t/font_2950010_8eaerzybg8i.woff2?t=1652781124146) format("woff2"),url(//at.alicdn.com/t/font_2950010_8eaerzybg8i.woff?t=1652781124146) format("woff"),url(//at.alicdn.com/t/font_2950010_8eaerzybg8i.ttf?t=1652781124146) format("truetype");
}

.iconfont {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: iconfont!important;
  font-size: 16px;
  font-style: normal;
}

.icon-aixin:before {
  content: "\eca1";
}

.icon-huangguan:before {
  content: "\e614";
}

.icon-huangguan1:before {
  content: "\e615";
}

.icon-huangguan2:before {
  content: "\e617";
}

.icon-V:before {
  content: "\e732";
}

.icon-jiangzhangxunzhang:before {
  content: "\e72d";
}

.icon-huore:before {
  content: "\e603";
}

.icon-xiangshang3:before {
  content: "\e76f";
}

.icon-xiangxia5:before {
  content: "\e774";
}

.icon-paixu:before {
  content: "\e664";
}

.icon-wrong:before {
  content: "\e6b7";
}

.icon-datiqia:before {
  content: "\e73d";
}

.icon-zu1412:before {
  content: "\e727";
}

.icon-icon-naozhong:before {
  content: "\e728";
}

.icon-yiliaohangyedeICON-:before {
  content: "\e636";
}

.icon-ditu1:before {
  content: "\e884";
}

.icon-VIP:before {
  content: "\e688";
}

.icon-beedaefafeefe:before {
  content: "\e602";
}

.icon-hongbao1:before {
  content: "\e678";
}

.icon-zuji:before {
  content: "\e601";
}

.icon-mima:before {
  content: "\e675";
}

.icon-daoru:before {
  content: "\e613";
}

.icon-icon_xinyong_xianxing_jijin-:before {
  content: "\e63f";
}

.icon-tikuzidian:before {
  content: "\e623";
}

.icon-back:before {
  content: "\e697";
}

.icon-shouye:before {
  content: "\e619";
}

.icon-erweima:before {
  content: "\e7ad";
}

.icon-zhuanfa1:before {
  content: "\e6c4";
}

.icon-diannao1:before {
  content: "\e625";
}

.icon-xiezuo:before {
  content: "\e604";
}

.icon-zhengshu-copy:before {
  content: "\e640";
}

.icon-jifen:before {
  content: "\e616";
}

.icon-daochuliebiao:before {
  content: "\e6fe";
}

.icon-yonhu:before {
  content: "\e657";
}

.icon-fuzhi:before {
  content: "\e600";
}

.icon-boda:before {
  content: "\e6e9";
}

.icon-weixinyundong:before {
  content: "\e6a1";
}

.icon-kongzhuangtai:before {
  content: "\e707";
}

.icon-tongbu:before {
  content: "\e6de";
}

.icon-fankui:before {
  content: "\e6fa";
}

.icon-ziliao:before {
  content: "\e630";
}

.icon-kefu:before {
  content: "\e641";
}

.icon-chuangjiangongdan:before {
  content: "\e783";
}

.icon-xingxing:before {
  content: "\e859";
}

.icon-banben:before {
  content: "\e60a";
}

.icon-dating:before {
  content: "\e7bd";
}

.icon-shanchu1:before {
  content: "\e67d";
}

.icon-zhaopian:before {
  content: "\e717";
}

.icon-more:before {
  content: "\e6a7";
}

.icon-icon:before {
  content: "\e6a0";
}

.icon-bianji:before {
  content: "\e60b";
}

.icon-ditu:before {
  content: "\e62a";
}

.icon-tuanduix:before {
  content: "\e60d";
}

.icon-shezhi:before {
  content: "\e65e";
}

.icon-fenxiang:before {
  content: "\e612";
}

.icon-rili:before {
  content: "\e64a";
}

.icon-paihangbang:before {
  content: "\e6f1";
}



.flex-center {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.flex-kai {
  display: flex;
  justify-content: space-between;
}

.flex-all-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.m5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt0 {
  margin-top: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.ml5 {
  margin-left: 5px;
}

.p0 {
  padding: 0;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p10 {
  padding: 10px;
}

.p5 {
  padding: 5px;
}

.pt5 {
  padding-top: 5px;
}

.pt15 {
  padding-top: 15px;
}

.p5 {
  padding-bottom: 5px;
}

.pt0 {
  padding-top: 0;
}

.pb5 {
  padding-bottom: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.plr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.ptm5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.fontbold {
  font-weight: 700;
}

.clearfix:after {
  clear: both;
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
}

.clear {
  clear: both;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font24 {
  font-size: 24px;
}

.font28 {
  font-size: 28px;
}

.font34 {
  font-size: 34px;
}

.color888 {
  color: #888;
}

.color444 {
  color: #444;
}

.color666 {
  color: #666;
}

.color999 {
  color: #999;
}

.colorfff {
  color: #fff;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

.textcenter {
  text-align: center;
}

.bgfff {
  background: #fff;
}

.ellipsis,.textover {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis--l2 {
  -webkit-line-clamp: 2;
}

.ellipsis--l2,.ellipsis--l3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis--l3 {
  -webkit-line-clamp: 3;
}

.wrap {
  word-wrap: break-word;
  word-break: break-all;
}

.color-green {
  color: #02b148;
}

.bg-green {
  background-color: #02b148;
}

.color-a {
  color: #72ace3;
}

.color-red {
  color: #e20f04;
}

.bg-red {
  background-color: #e20f04;
}

.color-pink {
  color: #ff86aa;
}

.color-primary {
  color: #2d8cf0;
}

.bg-primary {
  background-color: #2d8cf0;
}

.color-light-primary {
  color: #5cadff;
}

.bg-light-primary {
  background-color: #5cadff;
}

.color-dark-primary {
  color: #2b85e4;
}

.bg-dark-primary {
  background-color: #2b85e4;
}

.color-info {
  color: #2d8cf0;
}

.bg-info {
  background-color: #2d8cf0;
}

.color-success {
  color: #19be6b;
}

.bg-success {
  background-color: #19be6b;
}

.color-warning {
  color: #f90;
}

.bg-warning {
  background-color: #f90;
}

.color-error {
  color: #ed3f14;
}

.bg-error {
  background-color: #ed3f14;
}

.color-title {
  color: #1c2438;
}

.bg-title {
  background-color: #1c2438;
}

.color-content {
  color: #495060;
}

.bg-content {
  background-color: #495060;
}

.color-sub {
  color: #80848f;
}

.bg-sub {
  background-color: #80848f;
}

.color-disabled {
  color: #bbbec4;
}

.bg-disabled {
  background-color: #bbbec4;
}

.color-border {
  color: #dddee1;
}

.bg-border {
  background-color: #dddee1;
}

.color-divider {
  color: #e9eaec;
}

.bg-divider {
  background-color: #e9eaec;
}

.color-background {
  color: #f8f8f8;
}

.bg-background {
  background-color: #f8f8f8;
}

.color-ff8e1e {
  color: #ff8e1e;
}

.botton-none {
  background-color: initial;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: left;
}

.botton-none:after {
  content: none;
}

.no-list {
  padding-top: 10vh;
}

.no-list .iconfont {
  font-size: 80px;
}

page::after {
  animation: shadow-preload .1s;
  animation-delay: 3s;
  content: "";
  left: -1000px;
  position: fixed;
  top: -1000px;
}
.border-radius-xl{
  border-radius:40px;
}
.border-radius-lg{
  border-radius:30px;
}
.border-radius{
  border-radius:20px;
}
.border-radius-sm{
  border-radius:10px;
}
.border-radius-xs{
  border-radius:5px;
}


@-webkit-keyframes shadow-preload {
  0% {
    background-image: url(https://cdn1.dcloud.net.cn/img/shadow-grey.png);
  }

  100% {
    background-image: url(https://cdn1.dcloud.net.cn/img/shadow-grey.png);
  }
}

@keyframes shadow-preload {
  0% {
    background-image: url(https://cdn1.dcloud.net.cn/img/shadow-grey.png);
  }

  100% {
    background-image: url(https://cdn1.dcloud.net.cn/img/shadow-grey.png);
  }
}

[bind-data-custom-hidden="true"],[data-custom-hidden="true"] {
  display: none!important;
}
%flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}

.basis-xs {
  flex-basis: 20%;
}

.basis-sm {
  flex-basis: 40%;
}

.basis-df {
  flex-basis: 50%;
}

.basis-lg {
  flex-basis: 60%;
}

.basis-xl {
  flex-basis: 80%;
}

.flex-sub {
  flex: 1;
}

.flex-twice {
  flex: 2;
}

.flex-treble {
  flex: 3;
}

.flex-direction {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: flex-center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.align-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.center-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}
/*  -- 内外边距 -- */

.margin-0 {
  margin: 0;
}
.margin-xxs {
  margin: 5px;
}
.margin-xs {
  margin: 10px;
}

.margin-sm {
  margin: 20px;
}

.margin {
  margin: 30px;
}

.margin-lg {
  margin: 40px;
}

.margin-xl {
  margin: 50px;
}

.margin-top-xs {
  margin-top: 10px;
}

.margin-top-sm {
  margin-top: 20px;
}

.margin-top {
  margin-top: 30px;
}

.margin-top-lg {
  margin-top: 40px;
}

.margin-top-xl {
  margin-top: 50px;
}

.margin-right-xs {
  margin-right: 10px;
}

.margin-right-sm {
  margin-right: 20px;
}

.margin-right {
  margin-right: 30px;
}

.margin-right-lg {
  margin-right: 40px;
}

.margin-right-xl {
  margin-right: 50px;
}
.margin-bottom-xs {
  margin-bottom: 10px;
}
.margin-bottom-xxs {
  margin-bottom: 5px;
}
.margin-bottom-xxxs {
  margin-bottom: 2px;
}
.margin-bottom-sm {
  margin-bottom: 20px;
}

.margin-bottom {
  margin-bottom: 30px;
}

.margin-bottom-lg {
  margin-bottom: 40px;
}

.margin-bottom-xl {
  margin-bottom: 50px;
}

.margin-left-xs {
  margin-left: 10px;
}

.margin-left-sm {
  margin-left: 20px;
}

.margin-left {
  margin-left: 30px;
}

.margin-left-lg {
  margin-left: 40px;
}

.margin-left-xl {
  margin-left: 50px;
}

.margin-lr-xs {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-lr-sm {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-lr {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-lr-lg {
  margin-left: 40px;
  margin-right: 40px;
}

.margin-lr-xl {
  margin-left: 50px;
  margin-right: 50px;
}

.margin-tb-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-tb-sm {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-tb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-tb-lg {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-tb-xl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.padding-0 {
  padding: 0;
}

.padding-xs {
  padding: 10px;
}

.padding-sm {
  padding: 20px;
}

.padding {
  padding: 30px;
}

.padding-lg {
  padding: 40px;
}

.padding-xl {
  padding: 50px;
}

.padding-top-xs {
  padding-top: 10px;
}

.padding-top-sm {
  padding-top: 20px;
}

.padding-top {
  padding-top: 30px;
}

.padding-top-lg {
  padding-top: 40px;
}

.padding-top-xl {
  padding-top: 50px;
}

.padding-right-xs {
  padding-right: 10px;
}

.padding-right-sm {
  padding-right: 20px;
}

.padding-right {
  padding-right: 30px;
}

.padding-right-lg {
  padding-right: 40px;
}

.padding-right-xl {
  padding-right: 50px;
}

.padding-bottom-xs {
  padding-bottom: 10px;
}

.padding-bottom-sm {
  padding-bottom: 20px;
}

.padding-bottom {
  padding-bottom: 30px;
}

.padding-bottom-lg {
  padding-bottom: 40px;
}

.padding-bottom-xl {
  padding-bottom: 50px;
}

.padding-left-xs {
  padding-left: 10px;
}

.padding-left-sm {
  padding-left: 20px;
}

.padding-left {
  padding-left: 30px;
}

.padding-left-lg {
  padding-left: 40px;
}

.padding-left-xl {
  padding-left: 50px;
}

.padding-lr-xs {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-lr-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-lr {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-lr-lg {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-lr-xl {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-tb-xs {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-tb-sm {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-tb {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-tb-lg {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-tb-xl {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* -- 浮动 --  */

.cf::after,
.cf::before {
  content: " ";
  display: table;
}

.cf::after {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

/* ==================
          背景
 ==================== */

.line-red::after,
.lines-red::after {
  border-color: #d61201;
}

.line-orange::after,
.lines-orange::after {
  border-color: #f37b1d;
}

.line-yellow::after,
.lines-yellow::after {
  border-color: #fbbd08;
}

.line-olive::after,
.lines-olive::after {
  border-color: #8dc63f;
}

.line-green::after,
.lines-green::after {
  border-color: #39b54a;
}

.line-cyan::after,
.lines-cyan::after {
  border-color: #1cbbb4;
}

.line-blue::after,
.lines-blue::after {
  border-color: #0081ff;
}

.line-purple::after,
.lines-purple::after {
  border-color: #6739b6;
}

.line-mauve::after,
.lines-mauve::after {
  border-color: #9c26b0;
}

.line-pink::after,
.lines-pink::after {
  border-color: #f7467a;
}

.line-brown::after,
.lines-brown::after {
  border-color: #a5673f;
}

.line-grey::after,
.lines-grey::after {
  border-color: #8799a3;
}

.line-gray::after,
.lines-gray::after {
  border-color: #aaaaaa;
}

.line-black::after,
.lines-black::after {
  border-color: #1b1b1b;
}

.line-white::after,
.lines-white::after {
  border-color: #ffffff;
}

.bg-red {
  background-color: #d61201;
  color: #ffffff;
}

.bg-orange {
  background-color: #f37b1d;
  color: #ffffff;
}

.bg-yellow {
  background-color: #fbbd08;
  color: #000000;
}

.bg-olive {
  background-color: #8dc63f;
  color: #ffffff;
}

.bg-green {
  background-color: #39b54a;
  color: #ffffff;
}

.bg-cyan {
  background-color: #1cbbb4;
  color: #ffffff;
}

.bg-blue {
  background-color: #0081ff;
  color: #ffffff;
}

.bg-purple {
  background-color: #6739b6;
  color: #ffffff;
}

.bg-mauve {
  background-color: #9c26b0;
  color: #ffffff;
}

.bg-pink {
  background-color: #f7467a;
  color: #ffffff;
}

.bg-brown {
  background-color: #a5673f;
  color: #ffffff;
}

.bg-grey {
  background-color: #8799a3;
  color: #ffffff;
}

.bg-gray {
  background-color: #f0f0f0;
  color: #1b1b1b;
}

.bg-black {
  background-color: #1b1b1b;
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
  color: #666666;
}

.bg-shadeTop {
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.01));
  color: #ffffff;
}

.bg-shadeBottom {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
  color: #ffffff;
}

.bg-red.light {
  color: #d61201;
  background-color: #fadbd9;
}

.bg-orange.light {
  color: #f37b1d;
  background-color: #fde6d2;
}

.bg-yellow.light {
  color: #fbbd08;
  background-color: #fef2ced2;
}

.bg-olive.light {
  color: #8dc63f;
  background-color: #e8f4d9;
}

.bg-green.light {
  color: #39b54a;
  background-color: #d7f0dbff;
}

.bg-cyan.light {
  color: #1cbbb4;
  background-color: #d2f1f0;
}

.bg-blue.light {
  color: #0081ff;
  background-color: #cce6ff;
}

.bg-sky-blue {
  color: #0081ff;
  background-color: #80c3fc;
}

.bg-purple.light {
  color: #6739b6;
  background-color: #e1d7f0;
}

.bg-mauve.light {
  color: #9c26b0;
  background-color: #ebd4ef;
}

.bg-pink.light {
  color: #f7467a;
  background-color: #f9d7ea;
}

.bg-brown.light {
  color: #a5673f;
  background-color: #ede1d9;
}

.bg-grey.light {
  color: #8799a3;
  background-color: #e7ebed;
}

.bg-gradual-red {
  background-image: linear-gradient(45deg, #f43f3b, #ec008c);
  color: #ffffff;
}

.bg-gradual-orange {
  background-image: linear-gradient(45deg, #fe0151, #fa691c);
  color: #ffffff;
}

.bg-gradual-green {
  background-image: linear-gradient(45deg, #39b54a, #8dc63f);
  color: #ffffff;
}

.bg-gradual-purple {
  background-image: linear-gradient(45deg, #9000ff, #5e00ff);
  color: #ffffff;
}

.bg-gradual-pink {
  background-image: linear-gradient(45deg, #ec008c, #6739b6);
  color: #ffffff;
}

.bg-gradual-blue {
  background-image: linear-gradient(45deg, #0081ff, #1cbbb4);
  color: #ffffff;
}

.shadow[class*="-red"] {
  box-shadow: 6px 6px 8px rgba(204, 69, 59, 0.2);
}

.shadow[class*="-orange"] {
  box-shadow: 6px 6px 8px rgba(217, 109, 26, 0.2);
}

.shadow[class*="-yellow"] {
  box-shadow: 6px 6px 8px rgba(224, 170, 7, 0.2);
}

.shadow[class*="-olive"] {
  box-shadow: 6px 6px 8px rgba(124, 173, 55, 0.2);
}

.shadow[class*="-green"] {
  box-shadow: 6px 6px 8px rgba(48, 156, 63, 0.2);
}

.shadow[class*="-cyan"] {
  box-shadow: 6px 6px 8px rgba(28, 187, 180, 0.2);
}

.shadow[class*="-blue"] {
  box-shadow: 6px 6px 8px rgba(0, 102, 204, 0.2);
}

.shadow[class*="-purple"] {
  box-shadow: 6px 6px 8px rgba(88, 48, 156, 0.2);
}

.shadow[class*="-mauve"] {
  box-shadow: 6px 6px 8px rgba(133, 33, 150, 0.2);
}

.shadow[class*="-pink"] {
  box-shadow: 6px 6px 8px rgba(199, 50, 134, 0.2);
}

.shadow[class*="-brown"] {
  box-shadow: 6px 6px 8px rgba(140, 88, 53, 0.2);
}

.shadow[class*="-grey"] {
  box-shadow: 6px 6px 8px rgba(114, 130, 138, 0.2);
}

.shadow[class*="-gray"] {
  box-shadow: 6px 6px 8px rgba(114, 130, 138, 0.2);
}

.shadow[class*="-black"] {
  box-shadow: 6px 6px 8px rgba(26, 26, 26, 0.2);
}

.shadow[class*="-white"] {
  box-shadow: 6px 6px 8px rgba(26, 26, 26, 0.2);
}

.text-shadow[class*="-red"] {
  text-shadow: 6px 6px 8px rgba(204, 69, 59, 0.2);
}

.text-shadow[class*="-orange"] {
  text-shadow: 6px 6px 8px rgba(217, 109, 26, 0.2);
}

.text-shadow[class*="-yellow"] {
  text-shadow: 6px 6px 8px rgba(224, 170, 7, 0.2);
}

.text-shadow[class*="-olive"] {
  text-shadow: 6px 6px 8px rgba(124, 173, 55, 0.2);
}

.text-shadow[class*="-green"] {
  text-shadow: 6px 6px 8px rgba(48, 156, 63, 0.2);
}

.text-shadow[class*="-cyan"] {
  text-shadow: 6px 6px 8px rgba(28, 187, 180, 0.2);
}

.text-shadow[class*="-blue"] {
  text-shadow: 6px 6px 8px rgba(0, 102, 204, 0.2);
}

.text-shadow[class*="-purple"] {
  text-shadow: 6px 6px 8px rgba(88, 48, 156, 0.2);
}

.text-shadow[class*="-mauve"] {
  text-shadow: 6px 6px 8px rgba(133, 33, 150, 0.2);
}

.text-shadow[class*="-pink"] {
  text-shadow: 6px 6px 8px rgba(199, 50, 134, 0.2);
}

.text-shadow[class*="-brown"] {
  text-shadow: 6px 6px 8px rgba(140, 88, 53, 0.2);
}

.text-shadow[class*="-grey"] {
  text-shadow: 6px 6px 8px rgba(114, 130, 138, 0.2);
}

.text-shadow[class*="-gray"] {
  text-shadow: 6px 6px 8px rgba(114, 130, 138, 0.2);
}

.text-shadow[class*="-black"] {
  text-shadow: 6px 6px 8px rgba(26, 26, 26, 0.2);
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-mask {
  background-color: #1b1b1b;
  position: relative;
}

.bg-mask::after {
  content: "";
  border-radius: inherit;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.bg-mask view,
.bg-mask cover-view {
  z-index: 5;
  position: relative;
}

.bg-video {
  position: relative;
}

.bg-video video {
  display: block;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

/* ==================
          文本
 ==================== */

.text-xs {
  font-size: 20px;
}

.text-sm {
  font-size: 24px;
}

.text-df {
  font-size: 28px;
}

.text-lg {
  font-size: 32px;
}

.text-xl {
  font-size: 36px;
}

.text-xxl {
  font-size: 44px;
}

.text-sl {
  font-size: 80px;
}

.text-xsl {
  font-size: 120px;
}

.text-Abc {
  text-transform: Capitalize;
}

.text-ABC {
  text-transform: Uppercase;
}

.text-abc {
  text-transform: Lowercase;
}

.text-price::before {
  content: "¥";
  font-size: 80%;
  margin-right: 4px;
}

.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-cut-two {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-content {
  line-height: 1.6;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red,
.line-red,
.lines-red {
  color: #d61201;
}

.text-orange,
.line-orange,
.lines-orange {
  color: #f37b1d;
}

.text-yellow,
.line-yellow,
.lines-yellow {
  color: #fbbd08;
}

.text-olive,
.line-olive,
.lines-olive {
  color: #8dc63f;
}

.text-green,
.line-green,
.lines-green {
  color: #39b54a;
}

.text-cyan,
.line-cyan,
.lines-cyan {
  color: #1cbbb4;
}

.text-blue,
.line-blue,
.lines-blue {
  color: #0081ff;
}

.text-purple,
.line-purple,
.lines-purple {
  color: #6739b6;
}

.text-mauve,
.line-mauve,
.lines-mauve {
  color: #9c26b0;
}

.text-pink,
.line-pink,
.lines-pink {
  color: #f7467a;
}

.text-brown,
.line-brown,
.lines-brown {
  color: #a5673f;
}

.text-grey,
.line-grey,
.lines-grey {
  color: #575757;
}

.text-gray,
.line-gray,
.lines-gray {
  color: #aaaaaa;
}

.text-black,
.line-black,
.lines-black {
  color: #1b1b1b;
}

.text-white,
.line-white,
.lines-white {
  color: #ffffff;
}

.text-decoration {
  text-decoration: line-through
}

.el-dialog{
  display: flex;
  display: -ms-flex; /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column; /* 兼容IE */
  margin:0 !important;
  position:absolute  !important;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto; /* 兼容IE */
  overflow-y: auto;
  overflow-x: hidden;
}

.el-dialog__wrapper {
  /*隐藏ie和edge中遮罩的滚动条*/
  overflow: hidden;
}

</style>
