
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">
      <template >
        <el-tooltip content="搜索" effect="dark" placement="bottom">
        <search id="header-search" class="right-menu-item" />
        </el-tooltip>
          <el-tooltip content="错误日志" effect="dark" placement="bottom">
        <error-log class="errLog-container right-menu-item hover-effect" />
          </el-tooltip>
        <el-tooltip content="全屏" effect="dark" placement="bottom">
          <screenfull id="screenfull" class="right-menu-item hover-effect" />
        </el-tooltip>
        <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>
<!--        <lang-select class="right-menu-item hover-effect" />-->
        <el-tooltip content="设置" effect="dark" placement="bottom">
          <system-setting class="right-menu-item hover-effect">
            <settings></settings>
          </system-setting>
        </el-tooltip>
      </template>
      <el-tooltip content="通知" effect="dark" placement="bottom">
        <HeaderNotice class="right-menu-item hover-effect"></HeaderNotice>
      </el-tooltip>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        @command="head"
      >
        <div class="avatar-wrapper">
          <div style="display: inline">{{ name }}</div>
          <i class="el-icon-caret-bottom" />
        </div>

        <el-dropdown-menu slot="dropdown" @command="edit">
          <el-dropdown-item divided command="edit">
            <span style="display: block">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="lout">
            <span style="display: block">{{ $t("navbar.logOut") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="" :visible.sync="dialogFormVisible" width="35%" center>
      <el-form :model="form">
        <el-form-item label="原密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.old_psw"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.new_psw"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" :label-width="formLabelWidth">
          <el-input
            type="password"
            v-model="form.password2"
            auto-complete="off"
            @keyup.enter.native="edit"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
