let API_URL;
import settings from "@/settings.js";

if (window.location.href.indexOf("addons") !== -1) {
  API_URL = settings.apiBaseURL + "/addons/xunlian_suyuan/core/index.php/";
} else {
  API_URL = settings.apiBaseURL;
}

export default API_URL;
