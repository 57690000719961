import {request} from '@/common/axios.js'

/**
 * @description 系统新版本信息，用于更新显示
 */
export function getSystemVersion () {
  return request({
    url: 'system/admin/get_system_version',
    method: 'get',
  })
}

export function updateInfoList () {
  return request({
    url: 'system/admin/update_info',
    method: 'get',
  })
}
