<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
<!--        <img-->
<!--          v-if="siteInfo && siteInfo['login_logo']"-->
<!--          :src="siteInfo['login_logo']"-->
<!--          class="sidebar-logo-mini"-->
<!--        />-->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img
          v-if="siteInfo && siteInfo['login_logo']"
          :src="siteInfo['login_logo']"
          class="sidebar-logo"
        />
<!--        <h1 class="sidebar-title">{{ siteInfo && siteInfo.site_name }}</h1>-->
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '步数系统',
      logo: './images/icon.png',
      siteInfo:null
    }
  },
  mounted() {
    var that = this;
    this.act = localStorage.getItem("act");
    this.siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 100px;
  line-height: 50px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 150px;
      height: 70px;
      vertical-align: middle;
      margin:15px auto; ;
    }

    & .sidebar-logo-mini {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0;
    }
    height:0
  }
}
</style>
