<template>
  <span
    :style="price_styles"
    v-if="Number(value) > Number(shopPrice)"
    :class="{
      js_market_wrap: true,
      my_shop_price: true,
      'is-del': del,
    }"
    :data-orgp="value"
    :data-currency="currency"
    :data-original_amount="value"
  >
    {{ price_title }}￥{{ value }}
  </span>
</template>

<script>
/**
 * D网用的 my-shop-price
 * 其他网站 my_shop_price
 */
export default {
  name: "unit-market-price",
  props: {
    // 价格
    value: {
      default: "0.00",
      required: true,
    },
    price_title: {
      default: "市场价",
    },
    // 售价，如果售价高于市场价，则不展示市场价
    shopPrice: {
      required: true,
    },
    // 币种
    currency: {
      default: "CHS",
    },
    // 其他配置项
    styles: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 是否展示删除线
    };
  },
  computed: {
    del() {
      if (
        !this.styles ||
        !this.styles.global ||
        !this.styles.global.children_tabs
      ) {
        return false;
      }
      return this.styles.global.children_tabs.global.groups.market_price_del
        .value == 1
        ? true
        : false;
    },
    title() {
      if (
        !this.styles ||
        !this.styles.global ||
        !this.styles.global.children_tabs
      ) {
        return false;
      }
      return this.styles.global.children_tabs.global.groups.shop_price_title
        .value;
    },
    price_styles() {
      if (
        !this.styles ||
        !this.styles.global ||
        !this.styles.global.children_tabs
      ) {
        return "";
      }
      const text_size =
        this.styles.global.children_tabs.global.groups.text_size.value;
      const color =
        this.styles.global.children_tabs.global.groups.shop_price_color.value;
      const text_bold =
        this.styles.global.children_tabs.global.groups.text_bold.value;
      // const visibility = this.styles.global.children_tabs.global.groups.visibility.value;

      return `

                font-weight: ${text_bold};
                font-size: ${this.$px2rem(text_size)};
                color: ${color};
                align-items: center;
            `;
    },
  },
};
</script>

<style lang="less" scoped>
span.is-del {
  text-decoration: line-through;
}
</style>
